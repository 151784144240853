import Vue from 'vue'
import axios from 'axios'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import App from './App.vue'
import './assets/iconfont/iconfont.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import common from './common'

NProgress.configure({
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: true, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

Vue.use(ElementUI);
Vue.config.productionTip = false//不打印警告
Vue.prototype.$message = ElementUI.Message
Vue.prototype.$axios = axios
Vue.prototype.common = common.common
axios.defaults.withCredentials = true//每次请求携带cookie
// axios.defaults.baseURL = '/adminApi'

axios.interceptors.request.use(function (config) {
  // console.log("url",config);
  if (config.url.startsWith("/adminApi")) {
    config.headers.Authorization = localStorage.getItem("Authorization");
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  //如果是 adminApi的请求 且  不是 main 认证 
  if (response.config.url.startsWith("/adminApi")
    && !response.config.url.startsWith("/adminApi/auth")) {
    if (response.data.code === 401) {
      router.replace("/admin/login");
    }
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

new Vue({
  render: (function (createElement) {
    return createElement(App);
  }),
  store: store,
  router: router,
}).$mount('#app')