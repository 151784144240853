import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        openSlider: false,//是否关闭导航栏
        isPCM: false,//是否是移动端
        loading:false,
        editForm: {
            id: "",
            name: "",
            type: "",
            privacy: false,
            password: "",
        },//临时编辑文章内容
        editorContent: "",//临时编辑器内容
    },
    getters: {
        openSlider(state) {
            return state.openSlider;
        },
        isPCM(state) {
            return state.isPCM;
        },
        editForm(state) {
            return state.editForm;
        },
        editorContent(state) {
            return state.editorContent;
        },
        loading(state){
            return state.loading;
        }
    },
    mutations: {
        openSlider(state, v) {
            state.openSlider = v;
        },
        isPCM(state, v) {
            state.isPCM = v;
        },
        editForm(state, v) {
            state.editForm = v;
        },
        editorContent(state, v) {
            state.editorContent = v;
        },
        loading(state,v){
            state.loading=v;
        }
    }
})

export default store;