import Vue from 'vue';
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history', // 路由模式:默认为 hash,如果改为 history,则需要后端进行配合
  base: '/', // 基路径:默认值为'/'.如果整个单页应用在/app/下,base 就应该设为'/app/'.一般可以写成__dirname,在 webpack 中配置.
  routes: [
    {
      path: '/admin',
      component: () => import('./components/admin/main.vue'),
      redirect: '/admin/index',
      children: [
        {
          path: 'index',
          name: '首页',
          component: () => import('./components/admin/views/index.vue'),
          meta: { title: '首页', hidden: true }
        },
        {
          path: 'visitors',
          name: '当前在线',
          component: () => import('./components/admin/views/visitors.vue'),
          meta: { title: '当前在线' }
        },
        {
          path: 'blackVisitors',
          name: '黑名单',
          component: () => import('./components/admin/views/blackVisitors.vue'),
          meta: { title: '黑名单' }
        },
        {
          path: 'historyRequest',
          name: '历史访问',
          component: () => import('./components/admin/views/historyRequest.vue'),
          meta: { title: '黑历史访问' }
        },
        {
          path: 'newArticle',
          name: '文章发表',
          component: () => import('./components/admin/views/newArticle.vue'),
          meta: { title: '文章发表' }
        },
        {
          path: 'editArticle',
          name: '文章编辑',
          component: () => import('./components/admin/views/editArticle.vue'),
          meta: { title: '文章编辑' }
        },
        {
          path: 'comment',
          name: '留言板',
          component: () => import('./components/admin/views/comment.vue'),
          meta: { title: '留言板' }
        },
        {
          path: 'test',
          component: () => import('./components/blog/views/message.vue'),
          name: 'test',
        },
        {
          path: 'me',
          component: () => import('./components/admin/views/me/index.vue'),
          redirect: '/admin/me/main',
          name: 'me',
          meta: {
            title: 'me'
          },
          children: [
            {
              path: 'main',
              component: () => import('./components/admin/views/me/main.vue'),
              name: 'main',
              meta: { title: 'main' },
            },
            {
              path: 'show',
              component: () => import('./components/admin/views/me/show.vue'),
              name: 'show',
              meta: { title: 'show' },
            }
          ]
        },
      ]
    },
    {
      path: '/admin/login',
      name: '登录',
      component: () => import('./components/admin/views/login.vue'),
      meta: { title: '登录'}
    },
    {
      path: '/',
      component: () => import('./components/blog/main.vue'),
      redirect: '/index/1',
      children: [
        {
          path: 'index/:page*',
          component: () => import('./components/blog/views/index.vue'),
          name: 'index',
          props: true
        },
        {
          path: 'readBlog/:data*',
          component: () => import('./components/blog/views/readBlog.vue'),
          name: 'readBlog',
          props:true
        },
        {
          path: 'archives',
          component: () => import('./components/blog/views/archives.vue'),
          name: 'archives',
        },
        {
          path: 'message',
          component: () => import('./components/blog/views/message.vue'),
          name: 'message',
        },
        {
          path: 'about',
          component: () => import('./components/blog/views/about.vue'),
          redirect: '/admin/me',
          name: 'about',
          children: [
            {
              path: 'me',
              component: () => import('./components/blog/views/aboutShow.vue'),
              name: 'about/me',
              meta:{id:1}
            },
          ]
        },
      ]
    },
    {
      path: '*',
      component: () => import('./components/error.vue'),
      hidden: true
    }
  ]
})