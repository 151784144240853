const common={
    dateFormatter(d) {
        var date = new Date(d);
        var strDate = date.getFullYear() + "/";
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        strDate =
          strDate +
          (month > 8 ? month : "0" + month) +
          "/" +
          (day > 9 ? day : "0" + day) +
          " " +
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds);
        return strDate;
      },
      dateFormatToDay(d) {
        var date = new Date(d);
        var strDate = date.getFullYear() + "/";
        var month = date.getMonth() + 1;
        var day = date.getDate();
        strDate =
          strDate +
          (month > 8 ? month : "0" + month) +
          "/" +
          (day > 9 ? day : "0" + day);
        return strDate;
      },
      changetime(startTime) {
        var time = new Date().getTime() - startTime.getTime();
        var days = Math.floor(time / (24 * 3600 * 1000));
        var leave1 = time % (24 * 3600 * 1000);
        var hours = Math.floor(leave1 / (3600 * 1000));
        var leave2 = leave1 % (3600 * 1000);
        var minutes = Math.floor(leave2 / (60 * 1000));
        var leave3 = leave2 % (60 * 1000);
        var seconds = Math.round(leave3 / 1000);
        return (days + "天" + hours + "小时" + minutes + "分" + seconds + "秒");
    }
}

export default {
  common:common
}