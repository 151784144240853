<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/css/common.css'
import Resize from "./resizeWindow.js";

export default {
  name: "App",
  mixins: [Resize],
  components: {},
};
</script>

<style>
</style>
