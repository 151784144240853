const { body } = document;

export default {
    created() {
        this.changeResize();
    },
    beforeMount() {
        window.addEventListener("resize", this.changeResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.changeResize);
    },
    methods: {
        isPCM() {
            const rect = body.getBoundingClientRect();
            return rect.width <= 768;
        },
        changeResize() {
            //改变屏幕大小时触发
            if (this.isPCM()) {
                this.$store.commit("openSlider", true);
                this.$store.commit("isPCM", true);
            } else {
                this.$store.commit("openSlider", false);
                this.$store.commit("isPCM", false);
            }
        },
    }
}